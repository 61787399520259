/* width */
::-webkit-scrollbar {
  width: 15px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #808080cc;
}

::-webkit-scrollbar-thumb:hover {
  background: #888888ff;
}

::-webkit-scrollbar-track {
  background: #d6d6d611;
}
::-webkit-scrollbar-track:hover {
  background: #d6d6d622;
}

@font-face {
  font-family: "DXRigraf";
  src: url(../public/fonts/DXRigraf.otf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Atkinson Hyperlegible";
  src: url(../public/fonts/Atkinson-Hyperlegible-Regular-102.ttf);
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Atkinson Hyperlegible";
  src: url(../public/fonts/Atkinson-Hyperlegible-Italic-102.ttf);
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Atkinson Hyperlegible";
  src: url(../public/fonts/Atkinson-Hyperlegible-Bold-102.ttf);
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Atkinson Hyperlegible";
  src: url(../public/fonts/Atkinson-Hyperlegible-BoldItalic-102.ttf);
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "DMMono";
  src: url(../public/fonts/DMMono-Medium.ttf);
  font-weight: bold;
  font-style: italic;
}

html {
  user-select: none;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.background {
  position: fixed;
  background: linear-gradient(#282c34, #1b1f25);
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
a,
a:active,
a:visited {
  color: black;
}

a:hover {
  text-decoration: none;
  opacity: 0.5;
}

p {
  margin: 0;
}

body {
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  overscroll-behavior: none;
  background-color: #282c34;
  color: black;
  margin: 0;
  font-size: 11px;
  font-family: "Atkinson Hyperlegible";
}

.titleText {
  margin: 0;
  padding: 0;
  font-size: 22px;
  font-family: "DXRigraf";
  font-feature-settings: "ss01";
}

.subTitleText {
  margin-bottom: 3px;
}

h1 {
  margin: 0;
  padding: 0;
  font-size: 22px;
  margin-bottom: 10px;
  font-family: "DXRigraf";
  font-weight: normal;
}

h2 {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: "DXRigraf";
  font-weight: normal;
}

h3 {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: "DXRigraf";
  font-weight: normal;
  line-height: 1.2;
}

h5 {
  font-weight: 100;
  margin: 0;
  font-size: 10px;
}

h6 {
  font-weight: 100;
  font-style: italic;
  opacity: 0.4;
  margin: 0;
  font-size: 10px;
  line-height: 1.25;
}

.date {
  /* font-kerning: none;
  font-size: 11px;
  text-transform: uppercase;
  font-variant: small-caps;
  font-variant-numeric: tabular-nums lining-nums; */

  font-family: "DMMono";
  font-size: 9px;
}

/* MODULES */

.segment {
  display: flex;
  justify-content: center;
  scroll-snap-align: center;
  overflow: hidden;
}

.section {
  border-left: 2px solid #dddddd;
  padding-left: 15px;
  margin-top: 20px;
  overflow: hidden;
}

.twoColumn {
  display: flex;
  justify-content: space-between;
}

.equalColumn {
  min-width: 45%;
}

.smallColumn {
  width: 30%;
}

.largeColumn {
  width: 77%;
}

.midColumn {
  width: 68%;
}

.textBlock {
  /* line-height: 2; */
  margin-bottom: 0.2vh;
}

.fillColumn {
  width: 80%;
  border-left: 2px solid #dddddd;
  margin-left: 10px;
  padding-left: 15px;
}

/* STRUCTURE */

.sheet {
  /* transform: scale(0.95); */
  zoom: 0.95;
  width: 8.5in;
  height: 11in;
  background-color: white;
  filter: drop-shadow(8px 8px 6px rgba(0, 0, 0, 0.85));
  margin: 20px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.page {
  background-color: white;
  left: 0;
  top: 0;
  margin: 0.7in;
  margin-bottom: 0;
  padding: 0;
  width: auto;
  height: auto;
}

.controls {
  position: fixed;
  height: 100vh;
  right: 10px;
  top: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1000;
  opacity: 0;
  transition: 0.25s;
}

.button {
  user-select: none;
  cursor: pointer;
  background-color: #282c34;
  color: white;
  opacity: 0.75;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  margin: 20px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 27px;
  filter: drop-shadow(-3px -3px 5px rgb(81, 87, 99));
}
.button:hover {
  opacity: 1;
  background-color: #2d323b;
}
.button:active {
  background-color: #323742;
  filter: none;
}
.buttonLabel {
  text-align: right;
  margin-right: 3px;
  opacity: 0.75;
  font-size: 14px;
  color: white;
  visibility: hidden;
}
.buttonSet {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.pageCount {
  font-size: 12px;
  color: white;
  margin: 20px 30px 20px 10px;
  width: 45px;
  text-align: right;
  align-self: flex-end;
  opacity: 0.75;
}

/* SPECIFICS */

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

#printable {
  transition: ease-in-out 0.1s;
}

.logo {
  background-color: black;
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
  height: 40px;
  width: 40px;
  font-size: 40px;
  color: white;
  font-family: "DXRigraf";
  font-feature-settings: "ss01";
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
}

.bigLogo {
  font-family: "DXRigraf";
  font-feature-settings: "ss01";
  text-align: center;
  width: 100%;
  font-size: 25px;
  color: black;
  opacity: 0.3;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.socialIcons {
  margin: 5px;
  height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}

.logoType {
  display: flex;
  align-items: center;
}

.readerLogo {
  height: 40px;
  width: 40px;
  color: white;
  font-family: "DXRigraf";
  font-feature-settings: "ss01";
  font-size: 30px;
  margin: 20px;
  align-self: flex-end;
}

.contact {
  text-align: right;
  line-height: 1.35;
}

.intro {
  width: 100%;
  line-height: 1.5;
  margin-bottom: 20px;
  opacity: 0.33;
}

.skillTitle {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}

.skillSymbol {
  margin-right: 5px;
}

.spacer {
  height: 1px;
}

.television {
  width: 100%;
  border: 0.5px solid gray;
}

.podcast {
  width: 100%;
  border: 0.5px solid gray;
}

.digitalMagazine {
  width: 100%;
  border: 0.5px solid gray;
}

.printedMagazine {
  width: 100%;
  border: 0.5px solid gray;
}

.pageMarker {
  border-right: 2px solid #dddddd;
  border-bottom: 2px solid #dddddd;
  width: 7px;
  height: 7px;
  margin-top: -0.7in;
}

.footer {
  display: flex;
  align-self: flex-end;
  justify-self: flex-end;
  margin-right: 0.7in;
}

@media print {
  @page {
    size: letter portrait;
    margin: 0 !important;
  }

  .sheet {
    filter: none;
    zoom: 1;
    /* transform: scale(1); */
    margin: 0;
    width: 8.5in;
    height: 11in;
  }

  .controls {
    visibility: hidden;
    z-index: -1;
  }

  .background {
    visibility: hidden;
    z-index: -1;
  }
}
